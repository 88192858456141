<template>
	<v-row>
		<v-col cols="12" class="text-center d-flex justify-center align-center flex-column">
			<div class="py-16">
				<div style="font-size: 5em" class="my-0 font-weigh-600">403</div>
				<div class="mt-n7 mb-5" style="font-size: 1.4em">{{ errorMessage }}</div>
				<div>
					<v-btn @click="hasHistory ? $router.go(-1) : $router.push('/')" outlined color="button-color">
						<v-icon color="button-color" small left>fa-chevron-left</v-icon>
						geri dön
					</v-btn>
				</div>
			</div>
		</v-col>
	</v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	ame: '404',
	computed: {
		...mapGetters({}),
		hasHistory() {
			return window.history.length > 2;
		},

		errorMessage() {
			return 'Üzgünüz, bu kaynağa erişmek için yetkiniz yok veya kimlik doğrulama gerekiyor.';
		},
	},
};
</script>
<style scoped></style>
